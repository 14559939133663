import { render, staticRenderFns } from "./OrderShortage.vue?vue&type=template&id=de3bfd72&scoped=true&"
import script from "./OrderShortage.vue?vue&type=script&lang=js&"
export * from "./OrderShortage.vue?vue&type=script&lang=js&"
import style0 from "./OrderShortage.vue?vue&type=style&index=0&id=de3bfd72&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de3bfd72",
  null
  
)

export default component.exports