<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="materialModel" :items="materialLists" default="" item-value="jenis" item-text="jenis" label="Select Material" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="gradeModel" :items="gradeLists" default="" item-value="grade_id" item-text="grade_id" label="Select Grade" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="colorModel" :items="colorLists" default="" item-value="NamaWarna" item-text="NamaWarna" label="Select Color" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="thicknessModel" :items="thicknessLists" default="" item-value="thick" :item-text="item => item.thick + ' mm'" label="Select Thickness" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="widthModel" :items="widthLists" default="" item-value="width" :item-text="item => item.width + ' mm'" label="Select Width" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="qualityModel" :items="qualityLists" default="" item-value="quality_id" item-text="quality_id" label="Select Quality" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="categoryModel" :items="categoryLists" default="" item-value="category" item-text="category" label="Select Category" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardResult">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <h5 class="card-title">Result</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.Tebal`]="{ item }">
                                    {{ item.Tebal + ' mm' }}
                                </template>
                                <template v-slot:[`item.matrl_width`]="{ item }">
                                    {{ item.matrl_width + ' mm' }}
                                </template>
                                <template v-slot:[`item.panjang`]="{ item }">
                                    {{ item.panjang + ' m' }}
                                </template>
                                <template v-slot:[`item.berat`]="{ item }">
                                    {{ item.berat + ' T' }}
                                </template>
                            </v-data-table>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'GBRK Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order Shortage Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            categoryModel: '',
            categoryLists: [
                { category: 'ROOF', },
                { category: 'TRUSS' },
            ],
            materialModel: '',
            materialLists: [],
            gradeModel: '',
            gradeLists: [],
            qualityModel: '',
            qualityLists: [],
            colorModel: '',
            colorLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            itemLists: [],
            headers: [
                { text: 'ORD. DATE', value: 'TglPesan', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORD. RECEIVED', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'MATERIAL', value: 'jenis', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'THICKNESS', value: 'Tebal', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WIDTH', value: 'matrl_width', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COLOR', value: 'NamaWarna', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'GRADE', value: 'grd', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'QUALITY', value: 'grade', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'panjang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'qty', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT', value: 'berat', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatable: false,
            searchItem: '',
        }
    },
    async mounted(){
        
        this.initialize()
        document.querySelector(".cardResult").style.display = "none";

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)
            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderShortage`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                this.millLists = res.data.mill
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)
            })
        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderShortage/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    material: this.materialModel ? this.materialModel : '',
                    grade: this.gradeModel ? this.gradeModel : '',
                    color: this.colorModel ? this.colorModel : '',
                    thickness: this.thicknessModel ? this.thicknessModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    quality: this.qualityModel ? this.qualityModel : '',
                    product: this.productModel ? this.productModel : '',

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            });

        },

        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })

            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
            }
        },

        updateSalesmanDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getSalesmanById?officeid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            }
        },

        updateCustomerDropdown()
        {
            var id = $('#customer').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getCustomer?customer=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.customerLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Customer not found, please try again !',
                                icon: 'error',
                            })
                        }
                })
            } else {
                this.customerLists = []
            }
        },

        emptyCustomer(id)
        {
            if(id == null){
                this.customerLists = []
            }
        },

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>